import styled from 'styled-components';

export default styled.article`
  width: 100%;
  max-width: ${({ theme }) => theme.dimensions.desktop.content};
  padding: 2rem 0.5rem;

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.min}) {
    padding: 2rem 1rem;
    margin: 0 auto;
  }

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    padding: 4rem 2rem;
    margin: 0 auto;
  }
`;

export const Section = styled.section`
  width: 100%;
  margin: 4rem auto 0;
  max-width: ${({ theme }) => theme.dimensions.desktop.main};
  & > h2 {
    font-family: 'Bebas Neue';
    font-size: 2rem;
    color: ${({ theme }) => theme.colors.primary_dark};
    margin: 0;
    margin-bottom: 2rem;
  }
`;
