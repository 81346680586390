import styled from 'styled-components';

export default styled.h1`
  font-weight: 700;
  padding: 0;
  font-size: ${({ theme }) => theme.text.getSize('page-title')};
  line-height: 1.5em;
  margin-bottom: 1rem;

  @media (min-width: ${({ theme }) => theme.dimensions.mobile.max}) {
    line-height: 1.33em;
    margin-bottom: 1.5rem;
  }
`;
